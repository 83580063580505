import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from 'query-string';
import helpIcon from "../Assets/Help.svg"

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSigningIn: false,
            fetchError: false,
            helpNeeded: false
        };

        this.proceedLogin = this.proceedLogin.bind(this);
        this.Help = this.Help.bind(this);
    }

    Help() {
        this.setState({
            helpNeeded: true
        });
    }

    proceedLogin() {
        this.setState({
            fetchError: false,
            isSigningIn: true
        })
        fetch(process.env.REACT_APP_CLIENT_API + `/api/signin/${this.props.location.search}`, {
            method: 'GET',
            headers: {},
            credentials: "include"
        })
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((data) => {
                window.location.href = (queryString.parse(this.props.location.search).returnUrl) ? data.url + queryString.parse(this.props.location.search).returnUrl : data.url;
            })
            .catch((response) => {
                this.setState({
                    fetchError: true,
                    isSigningIn: false
                })
                console.log(response);
            });
    }

    render() {
        return (
            <div>
                <h6><label>Hi there</label></h6>
                <h3><label>Welcome to Cognite</label></h3>
                <label className="semi-label">where Cognite customers, partners, and users engage, learn, and grow.</label>&nbsp;
                <div class="rectangle"></div>
                <button className="btn btn-dark btn-lg btn-block" onClick={this.proceedLogin}>Already registered? Sign in here</button>
                <br></br>
                <Link to={(queryString.parse(this.props.location.search).system) ? `/signup/${this.props.location.search}` : "/signup"} className="btn btn-dark btn-lg btn-block">New to Hub? Register here</Link>
                <br></br>
                <div className="register-div">
                    
                <label className="register-text">Need help? Take a few seconds to look through this <a href="https://hub.cognite.com/welcome-to-cognite-hub-73/how-to-sign-up-for-cognite-hub-617" target="_blank">guide</a></label>&nbsp;
                    {/* <Link to={(queryString.parse(this.props.location.search).system) ? `/signup/${this.props.location.search}` : "/signup"} className={this.state.isSigningIn ? "btn btn-dark btn-lg btn-block disabled" : "btn btn-dark btn-lg btn-block"} >Register</Link> */}
                    {
                        this.state.fetchError ? <div class="error"><div class="error-box"></div><p className="error-text">Error occured. Please contact itsupport@cognite.com</p></div> : null
                    }
                </div>

                <div class="footer">
                    {
                        this.state.helpNeeded ?
                            // <label className="help-email-text">If you need help signing in, <br></br>please email us at <a href="mailto:hub@cognite.com?subject=Cognite Hub Sign-in Process">hub@cognite.com</a></label> :
                            <label className="help-email-text">If you still need help signing up, <br></br>please email us at <a href="mailto:hub@cognite.com?subject=Cognite Hub Sign-in Process">hub@cognite.com</a></label> :
                            <label className="help-text" onClick={this.Help}>Help<img className="help-icon" src={helpIcon} /></label>
                    }
                </div>
            </div>
        );
    }
}