import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import Register from "./components/signup.component";
import SignIn from "./components/home.component";
import brand from "./Assets/brand.png"
import landingPageC from "./Assets/Landingpage-C.png"
import landingPageN from "./Assets/Landingpage-N.png"

function App() {
  return (<Router>
    <div className="App">
      <nav className="navbar fixed-top">
        <div className="container">
          <img className="navbar-brand" src={brand}></img>
        </div>
      </nav>
      <div className="outer">
        <div className="inner">
      <img className="LandingpageC" src={landingPageC}></img>
          <Switch>
            <Route exact path='/' component={SignIn} />
            <Route exact path='/signup' component={Register} />
            <Redirect to='/' />
          </Switch>
        </div>
      </div>
      <img className="LandingpageN" src={landingPageN}></img>
    </div></Router>
  );
}

export default App;
