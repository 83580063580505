import React, { Component } from "react";
import backIcon from "../Assets/ChevronLeftMicro.svg"
import helpIcon from "../Assets/Help.svg"
import queryString from 'query-string';

class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            userName: "",
            redeemUrl: "",
            termsAgree: false,
            showTermsError: false,
            showCogniteEmailError: false,
            showUserNameError: false,
            showInvalidUserNameError: false,
            submited: false,
            invitedEmail: "",
            fetchError: false,
            systemParam: (queryString.parse(this.props.location.search).system) ? queryString.parse(this.props.location.search).system : "",
            returnParam: (queryString.parse(this.props.location.search).returnUrl) ? queryString.parse(this.props.location.search).returnUrl : "",
            userExists: false,
            helpNeeded: false,
            termsAgreeTime: ""
        };
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleTick = this.handleTick.bind(this);
        this.ExecuteApi = this.ExecuteApi.bind(this);
        this.NavigateToHome = this.NavigateToHome.bind(this);
        this.Help = this.Help.bind(this);
    }

    handleUserNameChange(event) {
        this.setState({
            showUserNameError: false,
            showInvalidUserNameError: false,
            userName: event.target.value
        });
    }

    handleEmailChange(event) {
        this.setState({
            showEmailError: false,
            userExists: false,
            email: event.target.value
        });
    }

    handleTick(event) {
        this.setState({
            showTermsError: false,
            termsAgree: event.target.checked,
            termsAgreeTime: new Date().toISOString()
        });
    }

    NavigateToHome() {
        if(!this.state.submited){
            this.setState({
                invitedEmail: "",
    
            });
            this.props.history.push(`/${this.props.location.search}`);
        }

    }

    Help() {
        this.setState({
            helpNeeded: true
        });
    }

    ExecuteApi() {
        this.setState({
            showUserNameError: false,
            showInvalidUserNameError: false,
            showEmailError: false,
            showTermsError: false,
            showCogniteEmailError: false,
        })
        if (this.state.userExists == true) {
        }

        else if (this.state.userName == "") {
            this.setState({
                showUserNameError: true
            })
        }

        else if (this.validateUserName() == false) {
            this.setState({
                showInvalidUserNameError: true
            })
        }

        else if (this.validateEmail() == false) {
            this.setState({
                showEmailError: true
            })
        }

        else if (this.state.email.toLowerCase().includes("@cognite.com")) {
            this.setState({
                showCogniteEmailError: true
            })
        }

        else if (this.state.termsAgree == false) {
            this.setState({
                showTermsError: true
            })
        }

        else {
            this.setState({
                submited: true
            })
            var data = {
                invitedUserDisplayName: this.state.userName,
                invitedUserEmailAddress: this.state.email,
                systemParam: this.state.systemParam,
                returnParam: this.state.returnParam,
                termsAgreeTime: this.state.termsAgreeTime
            };
            fetch(process.env.REACT_APP_CLIENT_API + `/api/invitation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        return response.json();
                    } else {
                        return null;
                    }
                })
                .then((data) => {
                    if (data.userExists) {
                        this.setState({
                            submited: false,
                            userExists: data.userExists
                        })
                    }

                    //generate Email
                    // else if (data.invitedUserEmailAddress) {
                    //     this.setState({
                    //         invitedEmail: data.invitedUserEmailAddress
                    //     })
                    // }

                    //skip email
                    else if (data.inviteRedeemUrl) {
                        window.location = data.inviteRedeemUrl;
                    }
                })
                .catch((response) => {
                    this.setState({
                        submited: false,
                        fetchError: true
                    })
                });
        }
    }

    validateEmail() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.state.email).toLowerCase());
    }

    validateUserName() {
        // const re = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;
        const re = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ]*)$/;
        return re.test(String(this.state.userName).toLowerCase());
    }

    render() {
        return (
            <div>
                {(!this.state.invitedEmail) ?
                    <div>
                        <fieldset disabled={this.state.submited}>
                            <h6><label>Welcome</label></h6>
                            <h3><label>Register</label></h3>
                            <div class="rectangle"></div>
                            <div className="form-group">
                                <label>User Name</label>
                                <input type="text" className="form-control" placeholder="User name" value={this.state.userName} onChange={this.handleUserNameChange} />
                                <label>Email</label>
                                <input type="email" className="form-control" placeholder="Your email" value={this.state.email} onChange={this.handleEmailChange} />
                                {
                                    this.state.showUserNameError ? <div class="error"><div class="error-box"></div><p className="error-text">Please enter your user name</p></div> : null
                                }
                                {
                                    this.state.showInvalidUserNameError ? <div class="error"><div class="error-box"></div><p className="error-text">Invalid user name.</p></div> : null
                                }
                                {
                                    this.state.showEmailError ? <div class="error"><div class="error-box"></div><p className="error-text">Please enter a valid email</p></div> : null
                                }
                                {
                                    this.state.showTermsError ? <div class="error"><div class="error-box"></div><p className="error-text">Please agree with the Terms and Privacy policy</p></div> : null
                                }
                                {
                                    this.state.showCogniteEmailError ? <div class="error"><div class="error-box"></div><p className="error-text">You need to sign up with your cognitedata.com account</p></div> : null
                                }
                                {
                                    this.state.fetchError ? <div class="error"><div class="error-box"></div><p className="error-text">Error occured. Please contact itsupport@cognite.com</p></div> : null
                                }
                                {
                                    this.state.userExists ? <div class="error"><div class="error-box"></div><p className="error-text">User already exists. Please login to continue</p></div> : null
                                }
                            </div>
                            {
                                <div className="mdfile">
                                    <br></br>
                                    <b>First time login consent</b><br/>
                                        <p>
                                            Cognite is committed to protecting your privacy. We need to process your personal data in order to provide you with access to Cognite Hub and Cognite Academy. For the purposes of giving access to Cognite Hub and Cognite Academy, we may process the following information about you: name, position, email address. 
                                            Please see our <a href="https://www.cognite.com/en/policy">privacy policy</a> for further information. Cognite Hub is built on inSided and all personal data is stored in the EU, for more information see: <a href="https://www.insided.com/docs/privacy-policy">https://www.insided.com/docs/privacy-policy</a>. Cognite Academy is built on Skilljar and all personal data is store in the US, for more information see: <a href="https://www.skilljar.com/privacy/">https://www.skilljar.com/privacy</a> 
                                            <br/><br/>Yes, I understand that Cognite Hub is a public community
                                        </p>
                                            <a href="https://www.insided.com/docs/privacy-policy">inSided - Privacy Policy</a><br/>
                                            We value your privacy, and take the utmost care of your data. Read our privacy policy to find out how.
                                            <br/><br/>
                                        <a href="https://www.skilljar.com/privacy/">Privacy Notice - Skilljar</a><br/>
                                        <p>Welcome to the website www.skilljar.com (part of the “Site,” as defined in the Membership and Use Agreement) of Skilljar Inc. (“we”, “us” or “our”). This Privacy Notice applies to the Site and Services (as defined in the Membership and Use Agreement) and describes the information that we collect.</p>
                                </div>
                            }<br></br>
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" checked={this.state.termsAgree} onChange={this.handleTick} />
                            <label className="custom-control-label semi-text" htmlFor="customCheck1">I understand and agree with the above
                            {/* <a href="https://hub.cognite.com/site/terms" target="_blank">Terms and Conditions </a> */}
                            {/* and <a href="https://www.cognite.com/en/policy" target="_blank">Privacy Policy.</a> */}
                        </label>
                        </div>
                    </div>
                            <button className="btn btn-dark btn-lg btn-block" onClick={this.ExecuteApi}>Sign up</button>
                        </fieldset>
                    </div> :
                    <div>
                        <h6>hi there</h6>
                        <h3>Check Email</h3>
                        <div class="rectangle"></div>
                        <div className="form-group">
                            <label className="semi-text text-center">We sent an invitation email to: <br></br> <b>{this.state.invitedEmail}</b>
                                <br></br>please check your email and click on the Accept invitation to continue.</label>
                        </div>
                    </div>
                }
                <div className="footerText">
                    <label className="back-text" onClick={this.NavigateToHome}><img src={backIcon} />Back</label>
                    <a className="termsandConditionsText" href="https://hub.cognite.com/site/terms" target="_blank">Terms and Conditions </a>
                </div>
                <div class="footer">
                    {
                        this.state.helpNeeded ?
                            <label className="help-email-text">If you need help with your registration, <br></br>please email us at <a href="mailto:hub@cognite.com?subject=Cognite Hub Registration Process">hub@cognite.com</a></label> :
                            <label className="help-text" onClick={this.Help}>Help<img className="help-icon" src={helpIcon}/></label>
                    }
                </div>
            </div >
        );
    }
}

export default SignUp;
